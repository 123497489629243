import { TFT_SET_12 } from '~/data/sets';
import { getConventionalRegionName } from '~/helpers/getConventionalRegionName';
import { NavItem } from '~/types/nav';
import { RiotPlatformRegion } from '~/types/riot';

export const navList: NavItem[] = [
  {
    name: 'Home',
    to: { name: getPageNames().ROUTE_HOME_NAME },
  },
  {
    name: 'Stats',
    to: { name: getPageNames().ROUTE_STATS_COMPS_NAME },
    isDropdownMenu: true,
    matchPath: '/stats/',
    subItems: [
      {
        name: 'Explorer',
        imageUrl: getTFTChampionTileUrl('TFT12_Smolder'),
        to: { name: getPageNames().ROUTE_STATS_EXPLORER_NAME },
        beta: true,
        blurb:
          'Time to sink yourself into the data and find the edge in your games!',
        isDropdownItem: true,
      },
      {
        name: 'Comps',
        imageUrl: getTFTChampionTileUrl('TFT12_Nasus'),
        to: { name: getPageNames().ROUTE_STATS_COMPS_NAME },
        blurb:
          'How is your favourite comp stacking up against the competition?',
        isDropdownItem: true,
      },
      {
        name: 'Champions',
        imageUrl: getTFTChampionTileUrl('TFT12_Bard'),
        to: { name: getPageNames().ROUTE_STATS_CHAMPIONS_NAME },
        blurb:
          'Which champion has the best average placement? Time to find out.',
        isDropdownItem: true,
      },
      {
        name: 'Items',
        imageUrl: getTFTItemUrl('TFT_Item_Leviathan', 'TFTSet12'),
        to: { name: getPageNames().ROUTE_STATS_ITEMS_NAME },
        blurb: 'Use your components wisely, know which Items perform the best!',
        isDropdownItem: true,
      },
      {
        name: 'Traits',
        imageUrl: getTFTTraitUrl('TFT12_Eldritch'),
        to: { name: getPageNames().ROUTE_STATS_TRAITS_NAME },
        blurb:
          'Is it worth leveling up to hit your next Trait tier? We know the answer.',
        imageSize: '2.2rem',
        isDropdownItem: true,
      },
      {
        name: 'Augments',
        imageUrl: getTFTAugmentUrl('TFT_Augment_DarkAlleyDealings', 'TFTSet12'),
        to: { name: getPageNames().ROUTE_STATS_AUGMENTS_NAME },
        blurb:
          'Are your favourite Augments really helping or hurting you? Explore the stats here!',
        imageSize: '2.4rem',
        isDropdownItem: true,
      },
    ],
  },
  {
    name: 'Leaderboard',
    to: {
      name: getPageNames().ROUTE_LEADERBOARD_NAME,
      params: {
        queueType: 'ranked',
        region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
      },
    },
    isDropdownMenu: true,
    matchPath: '/leaderboard/',
    subItems: [
      {
        name: 'Ranked Leaderboard',
        imageUrl: getTFTChampionTileUrl('TFT12_Ryze'),
        to: {
          name: getPageNames().ROUTE_LEADERBOARD_NAME,
          params: {
            queueType: 'ranked',
            region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
          },
        },
        blurb: 'Dominating ranked? See how high you can climb the leaderboard!',
        isDropdownItem: true,
      },
      {
        name: 'Top Players',
        imageUrl: getTFTChampionTileUrl('TFT12_Briar'),
        to: {
          name: getPageNames().ROUTE_LEADERBOARD_TOP_NAME,
          params: {
            queueType: 'ranked',
            region: getConventionalRegionName(RiotPlatformRegion.GLOBAL),
          },
        },
        blurb:
          "Who are the best players of the set? Here's who has held Rank 1 or Top 10 the longest!",
        isDropdownItem: true,
      },
    ],
  },
  {
    name: 'Database',
    to: getChampionPageLink({ set: TFT_SET_12, championName: 'ashe' }),
    isDropdownMenu: true,
    matchPath: '/database/',
    subItems: [
      {
        name: 'Champions',
        imageUrl: getTFTChampionTileUrl('TFT12_Neeko'),
        to: getChampionPageLink({ set: TFT_SET_12, championName: 'ashe' }),
        blurb:
          'Champions are at the core of TFT, make sure you know who they are!',
        isDropdownItem: true,
      },
      {
        name: 'Items',
        imageUrl: getTFTItemUrl('TFT_Item_JeweledGauntlet', 'TFTSet12'),
        to: getItemPageLink({ set: TFT_SET_12, itemName: 'adaptive-helm' }),
        blurb:
          'Items can turn a weak Champion into a beast, find the best to use here!',
        isDropdownItem: true,
      },
      {
        name: 'Augments',
        imageUrl: getTFTAugmentUrl('TFT_Augment_OopsAllRageblades', 'TFTSet12'),
        to: getAugmentPageLink({ set: TFT_SET_12, augmentName: 'afk' }),
        blurb:
          'The right Augment can be the difference between 1st and 8th, check them all out here!',
        imageSize: '2.4rem',
        isDropdownItem: true,
      },
      {
        name: 'Charms',
        imageUrl: getTFTChampionTileUrl('TFT12_Lillia'),
        to: { name: getPageNames().ROUTE_DATABASE_CHARMS_NAME },
        blurb:
          'Charms are powerful bonuses available in your shop, make sure you know them!',
        isDropdownItem: true,
      },
    ],
  },
];
