import { RouteRecordNameGeneric } from '#vue-router';

interface Args {
  matchPath?: string;
  routeName?: RouteRecordNameGeneric;
}

export const useRouteMatch = (props: Args) => {
  const route = useRoute();

  return computed(
      () =>
          (props.matchPath && route.path.includes(props.matchPath)) ||
          (props.routeName && route.name === props.routeName) ||
          false
  );
};
