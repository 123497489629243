<template>
  <div :class="['mobile-nav-subitem', { '--sub-item': isDropdownItem }]">
    <NuxtLink
      :class="[
        'mobile-nav-subitem__content',
        {
          'router-link-active': isRouteActive,
        },
      ]"
      :to="to"
    >
      <div v-if="icon || imageUrl" class="mobile-nav-subitem__icon">
        <Icon v-if="icon" :name="icon" size="1.6rem" />
        <TeamfightImage
          v-else
          :src="imageUrl || getFallbackIconUrl()"
          :style="{
            height: imageSize || '3.6rem',
            width: imageSize || '3.6rem',
            borderRadius: '0.3rem',
          }"
        />
      </div>

      <div class="mobile-nav-subitem__label">
        <span>
          {{ name }}
        </span>
        <div v-if="beta" class="mobile-nav-subitem__beta">BETA</div>
      </div>

      <div v-if="blurb" class="mobile-nav-subitem__blurb">
        {{ blurb }}
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { RouteLocationRaw, RouteLocationAsRelativeGeneric } from '#vue-router';
import { IconName } from '~/Icon/IconRegistry';
import { getFallbackIconUrl } from '~/utils/cdn';

interface Props {
  name: string;
  blurb?: string;
  to: RouteLocationRaw;
  icon?: IconName;
  imageUrl?: string;
  imageSize?: string;
  matchPath?: string;
  isDropdownMenu?: boolean;
  isDropdownItem?: boolean;
  beta?: boolean;
}

const props = defineProps<Props>();
const isRouteActive = useRouteMatch({
  routeName: (props.to as RouteLocationAsRelativeGeneric).name,
});
</script>

<style lang="scss" scoped>
.mobile-nav-subitem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.25s ease;
  fill: white;
  padding: 0 5%;

  .mobile-nav-subitem__content {
    display: grid;
    padding: 1rem;
    grid-template-columns: 3rem 1fr;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    gap: 0 2rem;
    transition: all 0.25s ease;

    .mobile-nav-subitem__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--BorderRadius-S);
      grid-row: span 2;
      border: 0.1rem solid var(--UI-Text);
      height: 3.8rem;
      width: 3.8rem;
      background-color: #1d1d1d;
    }

    .mobile-nav-subitem__label {
      font-weight: var(--FontWeight-Medium);
      display: flex;
      align-items: center;
      line-height: normal;
      font-size: 1.8rem;
      padding: 0 5%;
      font-size: 1.4rem;
      padding: 0;

      .mobile-nav-subitem__beta {
        transition: border 0.25s ease;
        display: flex;
        align-items: center;
        font-size: 1rem;
        border: none;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0.3rem;
        padding: 0.4rem 0.6rem;
        margin-left: 0.6rem;
        opacity: 0.6;
      }
    }

    .mobile-nav-subitem__blurb {
      font-weight: var(--FontWeight-Medium);
      opacity: 0.8;
      margin-top: 0.2rem;
      font-size: 1.2rem;
    }

    &:hover {
      background-color: rgba(241, 93, 59, 0.1);
      color: var(--Color-Orange);
      fill: var(--Color-Orange);
      border-radius: var(--BorderRadius-S);

      .mobile-nav-subitem__icon {
        border-color: var(--Color-Orange);

        img {
          border-color: var(--Color-Orange);
        }
      }

      .mobile-nav-subitem__beta {
        background: rgba(241, 93, 59, 0.2);
        color: var(--Color-Orange);
        border: none;
        opacity: 1;
      }
    }
  }

  .router-link-active {
    > .mobile-nav-subitem__label {
      opacity: 1;
      font-weight: var(--FontWeight-Bold);
      color: var(--Color-Yellow);
      filter: grayscale(0);
      fill: url(#tf-gradient);
      background: linear-gradient(243.96deg, #f1bd00 -25.57%, #f15d3b 160.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .mobile-nav-subitem__beta {
        background: rgba(241, 93, 59, 0.2);
        color: var(--Color-Orange);
        border: none;
        opacity: 1;
      }
    }
  }
}
</style>
