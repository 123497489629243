<template>
  <ClientOnly>
    <div v-if="isDev">
      <slot/>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const config = useRuntimeConfig();
const isDev = computed(() => config.public.environment === 'development');
</script>