<template>
  <header>
    <div class="top-header">
      <div class="inner-wrapper">
        <TheMobileNav/>

        <div class="logo-wrapper">
          <TeamfightLogo :size="3"/>

          <TeamfightTag v-if="userStore.isAdmin">
            ADMIN
          </TeamfightTag>

          <TeamfightTag v-else-if="userStore.isVIP">
            VIP
          </TeamfightTag>
        </div>

        <div class="search-wrapper">
          <SummonerSearch/>
        </div>

        <div class="top-header__actions">
          <LocalOnly>
            <TeamfightToggle
                v-model="useProdApiCookie().value"
                label="Prod API"
                @toggle="toggleClient"
            />
          </LocalOnly>

          <AdminOnly :styles="{ display: md ? 'flex' : 'none' }" alwaysShow subtle>
            <TeamfightToggle
                v-model="userStore.adminMode"
                :labelLink="`${getAdminUrl()}/home`"
                label="Admin Mode"
                @toggle="toggleAdminMode"
            />
          </AdminOnly>

          <NuxtLink class="discord" external href="https://discord.gg/3gDXwZ8pG9" target="_blank">
            <Icon color="white" name="discord" size="3.5rem"/>
          </NuxtLink>
        </div>
      </div>
    </div>

    <TheNavbar/>
  </header>
</template>

<script lang="ts" setup>
import { getAdminUrl } from '~/helpers/getAdminUrl';
import { useUserStore } from '~/stores/UserStore';
import Icon from '~/Icon/Icon.vue';
import { useProdApiCookie } from "~/utils/cookie";

const userStore = useUserStore();
const { md } = useScreen();

const toggleClient = (i: boolean) => {
  useProdApiCookie().value = i.toString();
};

const toggleAdminMode = (i: boolean) => {
  userStore.adminMode = i;
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: column;

  .top-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--TopNav-Height);
    background-color: var(--UI-Primary);
    padding: 0 5%;

    .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      max-width: var(--View-MaxWidth);
      margin: 0 auto;

      @include md {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .logo-wrapper {
        display: flex;
        align-content: center;
        gap: 1rem;

        @include md {
          margin-right: auto;
        }
      }

      .search-wrapper {
        display: none;
        flex: 1;

        @include md {
          display: grid;
        }
      }

      .top-header__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        @include md {
          gap: 2rem;
        }
      }
    }
  }
}
</style>
